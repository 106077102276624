import React, { useState, useEffect, useContext } from "react";
import scangif from "../../assets/img/qr-code-scanner-tuto.gif";
import StartBtn from "../../components/btnStart/BtnStart.js";
import ClipLoader from "react-spinners/ClipLoader";
import CustomPopup from "../../components/CustomPopup.js";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext.js";
import pageVisitTracker from "../../utils/hooks/VisitTracker.js";


const HomePage = () => {
  const [loading, setLoading] = useState(false);

  const { anonymeLogin, isLogin, setIsLogin, userId } = useContext(AuthContext);

  const navigate = useNavigate()

  const ignore = () => {
    anonymeLogin()
    setIsLogin(true)
  }


  window.onbeforeunload = (event) => { 
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = "Votre avancement sera abandonné"; // Legacy method for cross browser support
    }
    return ""; // Legacy method for cross browser support
  };


  /**
   * check si la page est chargée
   */
  useEffect(() => {
    setLoading(true);

    if (document.readyState === "complete") {
      setLoading(false);
    }
  }, [loading]);


  /**
   * check si la page est chargée aussi
   * @param {*} event 
   */
  window.onload = (event) => {
    setLoading(false);
    console.error("%c Attention! Evitez de manipulez l'application depuis la console au risque d'être bannie", "color: red; font-size: 24px;");

    /* console.log("La page est complètement chargée"); */
    localStorage.setItem('access', 'true')
  };

  /**
   * Comptabilise les visites de la page
   */
  useEffect(() => {
    pageVisitTracker()

    return () => {
      /* console.log('clean') */
    };
  }, []);


  return (
    <div>
      <div className="card bg-transparent text-center border-0">
        <div className="card-header bg-transparent border-0">
          <h3 className="header-1 mt-2" ><span>MAINTENANT,</span> <br /> <br />
          TU PEUX COMMENCER !</h3>
        </div>
        <div className="card-body d-flex flex-column justify-content-center align-items-center">
          <div className="scangif m-2 text-center">
            {loading ? (
              <ClipLoader loading={loading} size={80} />
            ) : (
              <img
                src={scangif}
                alt="Scan tuto"
                className="img-fluid rounded-circle scangif" 
              />
            )}
          </div>
          <StartBtn toUrl="/scanpage" text="commencer" />
        </div>
      </div>

      { !isLogin &&<CustomPopup openv={!isLogin} title="CONNECTE-TOI" content="Profite de plus d'avantages en te connectant." btnAction={()=> navigate('/signin')} btnTxt="JE ME CONNECTE" btnAction2={()=> ignore()} btnTxt2="IGNORER" />}

    </div>
  );
};

export default HomePage;
