import React from 'react';
import errorImage from '../../assets/img/404.png';
import '../../styles/main/error-pages.css';
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="card bg-transparent text-center border-0">
        <div className="card-header bg-transparent border-0">
            <h4 className="uniform-size" style={{fontSize:'1rem', backgroundColor:'#C4C4C4', textTransform:'uppercase'}}>
                Quelque chose s'est mal passé
            </h4>
          </div>
          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
        
            <img src={errorImage} style={{
                    width: '210px',   // Default width
                    height: 'auto',    // Keeps aspect ratio
                    maxWidth: '100%',  // Ensures responsiveness on smaller screens
                  }} alt="404 Illustration" />
            <button as={Link} to="/" style={{ margin:'40px', color:'white', backgroundColor:'#C4C4C4', lineHeight:'100%', height:'50px', width:'90%', fontSize:'1.25rem', fontWeight:'bold', padding:'15px 20px', borderRadius:'5px', textAlign:'center', display:'inline-block', textTransform:'uppercase' }}>
              Patienter et réessayer
            </button>
          </div>
          <div className="advertisement" style={{ marginTop: '20px' }}>
            {/* <img src={adImage} alt="Ad banner" /> */}
          </div>
        </div>
  );
};

export default Page404;
