import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import Popup from "reactjs-popup";
import scan from "../../assets/img/scan.gif";
// import limite from "../../styles/megaphone.png";
import useScanLimit from "../../hooks/useScanLimit.js";
// import CustomPopup from "../../components/CustomPopup.js";
import FormatPopup from "../../components/FormattedPopup.js";

const RedirectHandler = () => {
  const { idevent } = useParams();
  const navigate = useNavigate();
  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const { scanCount, cooldownEnd, incrementScanCount } = useScanLimit();
  const [hasIncremented, setHasIncremented] = useState(false);

  useEffect(() => {
    /* console.log('RedirectHandler - Initial state:', { idevent, scanCount, cooldownEnd }); */

    if (!idevent) {
      /* console.log('RedirectHandler - No idevent, redirecting to home'); */
      navigate('/');
      return;
    }

    const handleScan = () => {
      if (!hasIncremented) {
        const { newCount, limitReached } = incrementScanCount();
        /* console.log('RedirectHandler - New scan count:', newCount, 'Limit reached:', limitReached); */
        setHasIncremented(true);

        if (limitReached || (cooldownEnd && new Date() < cooldownEnd)) {
          /* console.log('RedirectHandler - Scan limit reached or cooldown active, showing popup'); */
          setShowLimitPopup(true);
        } else {
          /* console.log('RedirectHandler - Redirecting to /annonce'); */
          sessionStorage.setItem('currentIdevent', idevent);
          sessionStorage.setItem('hasPassedRedirect', 'true');
          
          setTimeout(() => {
            navigate('/annonce', { replace: true });
          }, 4000);
        }
      }
    };

    handleScan();
  }, [idevent, navigate, incrementScanCount, cooldownEnd, scanCount, hasIncremented]);

  const closeModal = () => {
    /* console.log('RedirectHandler - Closing limit popup, redirecting to /decompte'); */
    setShowLimitPopup(false);
    navigate('/decompte');
  };

  // const formattedContent = "Tu viens d’atteindre la limite de 5 scans. <strong>Réessaie dans 24h</strong>";


  if (showLimitPopup) {
    return (
      <FormatPopup 
        openv={true} 
        title="PETITE INFO"
        btnAction={closeModal}
        btnTxt="COMPRIS"  
      />
      
    );
  }
  

  return (
    <div className="card-body d-flex flex-column justify-content-center align-items-center">
      <div className="card-header bg-transparent border-0">
        <br />
        <br />
        <h3 className="title-s-1 mt-2">
          <span>CHARGEMENT...</span> <br /> <br />
        </h3>
      </div>
      <div className="scangif m-2 text-center">
        <img src={scan} alt="Loading" className="img-fluid scangif" />
      </div>
    </div>
  );
};

export default RedirectHandler;