
import { useState, useContext, useEffect } from 'react';
import SavedAdsComponent from '../../components/SavedAdsComponent';
import useCustomUseLoader from "../../utils/hooks/useCustomUseLoader";
import { AuthContext } from '../../context/AuthContext';
import { useLoaderData } from 'react-router';
import client from '../../utils/axiosClient';


export const SavedAds = () => {
  const { user, logout, isLogin,isAnonyme } = useContext(AuthContext);

  const { data, isLoading, error } = useLoaderData(); 

  return ( 
    <div className="max-w-md mx-auto p-4"> 
          <h2 style={{textAlign:'left', textTransform:'uppercase', fontSize:'30px'}}>Enregistrées</h2>
          <h3 className="h3" style={{fontSize:'15px'}}>Une liste des annonces que tu as enregistré</h3>
   
    {/* {data.map((ad, index) => (
        <SavedAdsComponent key={index} {...ad} />
      ))}

    </div>
  
  );
} */}

{isLoading ? (
        <p>Chargement des annonces...</p>
      ) : error ? (
        <p>Erreur lors du chargement des annonces.</p>
      ) : data && data.length > 0 ? (
        data.map((ad, index) => (
          <SavedAdsComponent key={index} {...ad} />
        ))
      ) : (
        <p>Pas d'annonces enregistrées pour le moment</p>
      )}
    </div>
  );
};

export const customUseLoader = async () => {

  const user = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;

  let error, isLoading, data = {}


      isLoading = true;
      /* console.log('Loading state:', isLoading); */
      try {
        const response = await client.get(`/favoris/${user.id}`);
        data = response.data.data ;
        /* console.log('Fetched data:', response.data.data); */ // Log the fetched data
      } catch (error) {
        error = error;
        /* console.log('Error fetching data:', error); */ // Log the error
      } finally {
        isLoading = false;
        /* console.log('Loading state:', isLoading); */ // Log the loading state
      }

  return { data, isLoading, error };
};