import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from "../../components/ButtonPrimary";
import styled from 'styled-components';
import useScanLimit from "../../hooks/useScanLimit";
import "../../styles/pages/CoolDownPage.css"

const FlipClockContainer = styled.div`
  .main-example {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    text-align: center;
    padding: 40px;
    background-color: #f0f0f0;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
  .main-example .countdown-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 180px;
  }
  .main-example .time {
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    display: inline-block;
    text-align: center;
    position: relative;
    height: 140px;
    width: 100px;
    perspective: 479px;
    backface-visibility: hidden;
    transform: translateZ(0);
    transform: translate3d(0,0,0);
  }
  .main-example .count {
    background: #FF3D33;
    color: #f8f8f8;
    display: block;
    font-family: 'Oswald', sans-serif;
    font-size: 3em;
    line-height: 140px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    top: 0;
    width: 100%;
    transform: translateZ(0);
    transform-style: flat;
  }
  .main-example .count.top {
    border-top: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    border-radius: 5px 5px 0 0;
    height: 50%;
    transform-origin: 50% 100%;
  }
  .main-example .count.bottom {
    background-image: linear-gradient(rgba(255,255,255,0.1), transparent);
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 0 0 5px 5px;
    line-height: 0;
    height: 50%;
    top: 50%;
    transform-origin: 50% 0;
  }
  .main-example .count.next {
  }
  .main-example .label {
    font-size: 1em;
    margin-top: 10px;
    display: block;
    position: absolute;
    top: 140px;
    color: #333;
  }
  .main-example .count.curr.top {
    transform: rotateX(0deg);
    z-index: 3;
  }
  .main-example .count.next.bottom {
    transform: rotateX(90deg);
    z-index: 2;
  }
  .main-example .flip .count.curr.top {
    transition: all 250ms ease-in-out;
    transform: rotateX(-90deg);
  }
  .main-example .flip .count.next.bottom {
    transition: all 250ms ease-in-out 250ms;
    transform: rotateX(0deg);
  }
  .main-example {
    width: 100%;
  }
  .main-example .countdown-container {
    height: 100px;
  }
  .main-example .time {
    height: 70px;
    width: 48px;
  }
  .main-example .count {
    font-size: 1.5em;
    line-height: 70px;
  }
  .main-example .label {
    font-size: 0.8em;
    top: 72px;
  }
`;

const FlipClock = ({ hours, minutes }) => {
  const [flip, setFlip] = useState({hours: false, minutes: false});

  useEffect(() => {
    setFlip({hours: false, minutes: true});
    if (minutes === '59') {
      setFlip(prev => ({...prev, hours: true}));
    }
    const timer = setTimeout(() => setFlip({hours: false, minutes: false}), 500);
    return () => clearTimeout(timer);
  }, [hours, minutes]);

  return (
    <FlipClockContainer>
      <div className="main-example">
        <span >TEMPS RESTANT</span>
        <div className="countdown-container" style={{padding:'10px'}}>
          <div className={`time hours ${flip.hours ? 'flip' : ''}`}>
            <span className="count curr top">{hours}</span>
            <span className="count next top">{hours}</span>
            <span className="count next bottom">{hours}</span>
            <span className="count curr bottom">{hours}</span>
            <span className="label">Heures</span>
          </div>
          <div className={`time minutes ${flip.minutes ? 'flip' : ''}`}>
            <span className="count curr top">{minutes}</span>
            <span className="count next top">{minutes}</span>
            <span className="count next bottom">{minutes}</span>
            <span className="count curr bottom">{minutes}</span>
            <span className="label">Minutes</span>
          </div>
        </div>
      </div>
    </FlipClockContainer>
  );
};

const CooldownPage = () => {
  const [timeRemaining, setTimeRemaining] = useState({ hours: 24, minutes: 0 });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { cooldownEnd, resetScanCount } = useScanLimit();

  const updateTimer = useCallback(() => {
    if (!cooldownEnd) {
      setIsButtonDisabled(false);
      setTimeRemaining({ hours: 0, minutes: 0 });
      return;
    }

    const now = new Date();
    const diff = cooldownEnd.getTime() - now.getTime();

    if (diff <= 0) {
      setIsButtonDisabled(false);
      setTimeRemaining({ hours: 0, minutes: 0 });
      resetScanCount();
    } else {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setTimeRemaining({ hours, minutes });
      setIsButtonDisabled(true);
    }
  }, [cooldownEnd, resetScanCount]);

  useEffect(() => {
    updateTimer();
    const timer = setInterval(updateTimer, 60000);
    return () => clearInterval(timer);
  }, [updateTimer]);

  const handleScanAgain = () => {
    if (!isButtonDisabled) {
      navigate('/scanpage');
    }
  };

  if (!cooldownEnd) {
    navigate('/scanpage');
    return null;
  }

  return (
    <div className="text-center">
      <div className="card bg-transparent text-center border-0">
        <div className="card-header bg-transparent border-0">
          <h3 className="uniform-size pseudo-button">Attends le décompte</h3>
        </div>

        <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
          <FlipClock 
            hours={timeRemaining.hours.toString().padStart(2, '0')} 
            minutes={timeRemaining.minutes.toString().padStart(2, '0')} 
          />
        </div>

        <ButtonPrimary 
          toUrl="/scanpage" 
          text="CONTINUER" 
          action={handleScanAgain} 
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default CooldownPage;