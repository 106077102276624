import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import src from "../../assets/img/logo192.png";
import { MainFlowContext } from "../../context/FlowContext";
import menu from "../../styles/menu-button.png";


const OffCanvasMenu = () => {

  const { toggleMenu, closeMenu } = useContext(MainFlowContext); 

  return (
    <nav className="navbar bg-body-tertiary" style={{zIndex : '1', paddingLeft:'1px', paddingRight:'1px'}}>
      <div className="container-fluid" style={{ paddingLeft:'2px', paddingRight:'2px'}}>
        <NavLink to="/" className="navbar-brand" onClick={closeMenu} >
          <img src={src} alt="logo scangift" width="40px" className="img-fluid rounded d-inline" />

        </NavLink>
        <button className="btn" type="button" onClick={toggleMenu} >
          <img src={menu} alt="Menu" width="24" height="24" />
        </button>
       
      </div>
    </nav>
  );
}

export default OffCanvasMenu;