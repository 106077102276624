import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import image from '../assets/img/fruitizz.jpg'
import ButtonGroup from '@mui/material/ButtonGroup';
import { del, post } from '../utils/functions/ApiUtils';
import { useFetcher } from "react-router-dom";
import axios from 'axios';

const SavedAdsComponent = ( { id = 125936, nom = 'Lorem Ipsum dolor ame' , url_des = 'https://gle.maps/fruitizz', medias = [image], code_event = 'Fruitizz-missebo', adresse = 'Cotonou'  } ) => {

const fetcher = useFetcher();

const handleClick = ( id ) => {

  // On appelle la fonction fetcher.submit avec l'URL, la méthode et les données
  fetcher.submit( { id: id }, {action : "/annonces-gardees", method: "delete"})
};


return ( 

    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'justify'
      }}

      className='my-4'
    >
      <Card
        style={{
          overflow: 'hidden',
          width: '100%',
          margin: 'auto',  // Centre horizontalement
          boxShadow:'0px 4px 2px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
        }}
      >
        <CardContent>
        <div
          style={{
            alignItems: "start",
            display: "flex",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              flexShrink: 0,
            }}
          >
            <img
              alt="l'annonce"
              height="120"
              src={process.env.REACT_APP_API_BASE_URL + "/" + /* medias[0] */ (medias && medias[0] ? medias[0].url_med : '')}
              style={{
                aspectRatio: "100/120",
                height: 120,
                objectFit: "cover",
                width: 100,
              }}
              width="100"
            />
          </div>
          <div style={{
              flexShrink: 0,
            }}>
            <div>#{id}</div>
            {/* <h3 className='subtitle mt-4'   style={{
                marginTop: 8,
                marginBottom:0,
              }}
              >Borne d'origine :</h3> */}
            <div style={{
              color: '#868D95'
            }}>{code_event}</div>
            {/* <h3 className='subtitle'
              style={{
                marginTop: 8,
                marginBottom:0,
              }}
            >
              Lieu :
            </h3>
            <div>{adresse}</div> */}
            <h3 className='subtitle'
              style={{
                marginTop: 30,
                marginBottom:0,
              }}
            >
              Destination :
            </h3>
            <div>
              <a
                href={url_des}
                target='_blank'
                rel='noreferrer'
                style={{
                  textDecoration: "underline",
                  color: '#868D95'
                }}
              >
                {url_des}
              </a>
            </div>

            <div
          style={{
            /* backgroundColor: '#F5F5f5', */
            marginTop: 30,
            padding: 5,
          }}
        >
            <div  style={{
              height:'auto'
            }}
            className="flex gap-2">
              <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors text-sm font-medium" style={{backgroundColor:'#0E61CB'}} href={url_des} target='_blank' > VISITER </button>
              <fetcher.Form method="post" action={`/annonces-gardees`}>
              <button type='submit' name="id" value={id} className=' px-4 p-2 bg-gray-100 rounded hover:bg-gray-200 transition-colors' style={{backgroundColor:'#EFEFFD'}} > <i className="fas fa-trash-alt" style={{color:'#FF3D33'}}></i> </button>

    </fetcher.Form>
              {/* <Button className='w-25 bg-secondary' onClick={() => fetch('/annonces-gardees', { method : 'post', body: JSON.stringify({ 'id' : id}) }) } > <i className="fas fa-trash-alt"></i> </Button> */}

            </div>
        </div>
          </div>
          
        </div>
        {/* <div
          style={{
            marginTop: 16,
            marginBottom:0,
          }}
        >
          <h3 className='subtitle'>Message</h3>
          <div>{nom} </div>
        </div> */}
      </CardContent>
      </Card>
    </div>
  );
}

export default SavedAdsComponent;
