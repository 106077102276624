import { useState, useContext,useEffect } from 'react';
import Login from '../../utils/functions/auth/Login';
import SignUp from './SignUp';
import SignIn from './SignIn';
import PreferenceSetup from './PreferenceSetup';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router';
import MonCompte from '../main/MonCompte';
import ProfileSetup from './ProfileSetup';



function Multistep() {

	const { currentStep, user, setUser, finalData } = useContext(AuthContext);
	const navigate = useNavigate()
	
	function showStep(step) {

		/* switch (step) {
			case 1:
				return <PreferenceSetup />
			case 2:
				return <SignUp />
			case 3:
				return <ProfileSetup />
			case 4:
				return <MonCompte />
			default:
				navigate('/')
		} */
				switch (step) {
					case 1:
					  return <ProfileSetup />; // Afficher ProfileSetup en premier
					case 2:
					  return <PreferenceSetup />; // Afficher PreferenceSetup ensuite
					/* case 3:
					  return <SignUp />; */
					case 3:
					  return <MonCompte />;
					default:
					  navigate('/');
				  }
	}

	return (
		<>
		{ showStep(currentStep)  }
		</>
	);
}

export default Multistep;