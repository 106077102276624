import { useState, useEffect, useCallback } from 'react';

const useScanLimit = () => {
  const [scanCount, setScanCount] = useState(() => {
    const stored = localStorage.getItem('scanCount');
    return stored ? parseInt(stored, 10) : 0;
  });
  const [cooldownEnd, setCooldownEnd] = useState(() => {
    const stored = localStorage.getItem('cooldownEnd');
    return stored ? new Date(parseInt(stored, 10)) : null;
  });

  const resetScanCount = useCallback(() => {
    /* console.log('useScanLimit - Resetting scan count'); */
    setScanCount(0);
    setCooldownEnd(null);
    localStorage.setItem('scanCount', '0');
    localStorage.removeItem('cooldownEnd');
  }, []);

  useEffect(() => {
    const checkAndResetIfNeeded = () => {
      const now = new Date();
      if (cooldownEnd && now > cooldownEnd) {
        resetScanCount();
      }
    };

    checkAndResetIfNeeded();
    const intervalId = setInterval(checkAndResetIfNeeded, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [cooldownEnd, resetScanCount]);

  const incrementScanCount = useCallback(() => {
    const now = new Date();
    if (cooldownEnd && now < cooldownEnd) {
      /* console.log('useScanLimit - Cooldown active, not incrementing'); */
      return { newCount: scanCount, limitReached: true };
    }

    const newCount = scanCount + 1;
    setScanCount(newCount);
    localStorage.setItem('scanCount', newCount.toString());
    /* console.log('useScanLimit - Incremented scan count:', newCount); */

    if (newCount > 5 ) {
      const newCooldownEnd = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      setCooldownEnd(newCooldownEnd);
      localStorage.setItem('cooldownEnd', newCooldownEnd.getTime().toString());
      /* console.log('useScanLimit - Cooldown set until:', newCooldownEnd); */
      return { newCount, limitReached: true };
    }

    return { newCount, limitReached: false };
  }, [scanCount, cooldownEnd]);

  return {
    scanCount,
    cooldownEnd,
    incrementScanCount,
    resetScanCount,
  };
};

export default useScanLimit;