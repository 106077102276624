import React from 'react';

/**
 * 
 * @param {String} text - texte du bouton
 * @param {String} toUrl - url de destination 
 * @param {function} action - fonction à exécuter avant d'ouvrir l'url
 * @param {Boolean} disabled - si le bouton doit être désactivé
 * @returns 
 */
const ButtonPrimary = ({ text, toUrl, action, disabled }) => {

    return (
        <div className="btn-principal mx-auto text-center" onClick={!disabled ? action : null}>
            <a href={disabled ? "#" : toUrl} target="_self" rel='noreferrer'>
                <button 
                    /* className="button-79 rounded "  */
                    disabled={disabled} // Désactiver le bouton ici
                    style={{ backgroundColor: disabled ? '#C4C4C4' : '#FF3D33', margin:'10px', lineHeight:'100%', height:'50px', width:'90%', fontSize:'1.25rem', fontWeight:'bold', padding:'15px 20px', color:'white', borderRadius:'5px', textAlign:'center', display:'inline-block'}} // Gérer la couleur du bouton
                >
                    {text}
                </button>
            </a>
        </div>
    );
};

export default ButtonPrimary;