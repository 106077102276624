import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import PrefButton from "../../components/PrefButton";
import { Checkbox, TextField, FormControlLabel, Button } from "@mui/material";
import { MuiTelInput } from 'mui-tel-input'
import { NavLink } from 'react-router-dom';


const MonCompte = () => {

    const { finalData, user, prefList, isPreferenceSelected, handlePreferenceClick, setUser, setFinalData,setSelectedPreferences, userUpdate } = useContext(AuthContext);

    const userData = JSON.parse(localStorage.getItem('userData'))
    const dateNaissance = new Date(userData.date_naissance);
    const formattedDate = dateNaissance.toLocaleDateString('fr-FR'); // Exemple: '06/11/2024'
 
    const preference = [userData.preferences]

    useEffect(() => {
        setFinalData(userData)
        setUser(userData) 
    }, []);

    useEffect(() => {
        preference.forEach(element => {
            setSelectedPreferences(element)
        });
    }, []);


    const handleChange = (newValue) => {
        setUser({ ...user, 'tel': newValue })
    }

    const goBack = () => {
        /* console.log('mettre à jour') */
    }
    return (
        <div className='text-center'>
            {/*             
            <p>Identifiant : { userData.username } </p>
            <p>Nom : { userData.fullname } </p>
            <p>Email : { userData.email } </p>
            <p>Numéro : { userData.tel } </p>
            */}
            {/* <div className="flex flex-col items-center mt-4">
                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp" className="rounded-circle" style={{ "width": "150px" }}
                    alt="Avatar" />

                <i className="fas fa-camera"></i>
                <div className="header-2 font-semibold mt-2">{userData.fullname}</div>
            </div>
            <div className='divider'></div> */}

            <div className="d-flex flex-column align-items-start mx-4 mt-6">
                <h2 className="text-dark mb-1">MON COMPTE</h2>
                <h3 className="h3">Voici les infos que tu nous avais donné au début</h3>
                <TextField label="Nom" value={userData.nom} onChange={(e) => setUser({ ...user, 'nom': e.target.value })} margin="normal" variant='standard' color="primary" type="text" id="nom" name="nom" className='w-100 mt-0'/>

                <MuiTelInput label="Numéro de téléphone" defaultCountry="BJ" value={userData.tel} onChange={handleChange} margin="normal" name="tel" id="tel" className='mt-4 w-100' variant='standard' />

                <TextField label="Adresse mail" value={userData.email} onChange={(e) => setUser({ ...user, 'email': e.target.value })} margin="normal" variant="standard" color="primary" type="email" id="email" name="email" className='w-100 mt-0' />

{/* Rajout pour mettre à jour le compte */}
                <TextField label="Date de naissance" value={formattedDate} onChange={(e) => setUser({ ...user, 'date_naissance': e.target.value })} margin="normal" variant="standard" color="primary" type="text" id="date_naissance" name="date_naissance" className='w-100 mt-0' />

                <TextField label="Occupation" value={userData.occupation} onChange={(e) => setUser({ ...user, 'occupation': e.target.value })} margin="normal" variant="standard" color="primary" type="text" id="occupation" name="occupation"className='w-100 mt-0' />

                <TextField label="Adresse" value={userData.adresse} onChange={(e) => setUser({ ...user, 'adresse': e.target.value })} margin="normal" variant="standard" color="primary" type="text" id="adresse" name="adresse" className='w-100 mt-0' />


                    <h3 className="text-dark my-4">Preferences </h3>
                <div className="row mb-4" >
                    {prefList.map(pref => {
                        return (
                            <PrefButton condition={isPreferenceSelected(pref)} key={pref} onClickFunction={() => handlePreferenceClick(pref)} > {pref} </PrefButton>
                        )
                    })}
                </div>
                {/* <div className="">
            <div className="mb-1">
              <div className="text-sm font-semibold text-gray-600">Nom et prénom</div>
              <input className="text-lg border rounded p-2" defaultValue="John Daniel" type="text" />
            </div>
            <div className="mb-1">
              <div className="text-sm font-semibold text-gray-600">Email</div>
              <input className="text-lg border rounded p-2" defaultValue="rahul1988@gmail.com" type="email" />
            </div>
            <div className="mb-1">
              <div className="text-sm font-semibold text-gray-600">Whatsapp</div>
              <input className="text-lg border rounded p-2" defaultValue="wa.me/8085550111" type="text" />
            </div>
          </div> */}
            </div>

            {/* <div key="1" className="flex justify-between max-w-xs mx-auto text-white rounded-lg">
      <Button className="bg-secondary text-white rounded-l-lg px-4 py-3 hover:bg-[#a31645]">
        <NavLink to="/scanpage" >Scanner</NavLink>
        </Button>
      <Button className="bg-tertiary text-primary fw-bolder rounded-r-lg px-4 py-3 hover:bg-[#a31645]"> <strong> Mettre à jour</strong></Button>
    </div> */}

    <div
      key="1"
      className="d-flex justify-content-center mx-auto text-white bg-tertiary rounded"
      style={{
        maxWidth: "24.5rem",
        maxHeight: "2.5rem"
      }}
    >
      <Button className="flex-grow-1 text-light rounded-3 px-4 py-3 hover:bg-dark" style={{backgroundColor:'#FF3D33'}}> <NavLink to="/scanpage" className="text text-light " style={{textDecoration : "none", fontSize:'12px'}} >Supprimer mon compte</NavLink></Button>
      <Button className="flex-grow-1 text-primary  bg-tertiary  rounded-3 px-4 py-3 hover:bg-dark fw-bold text" onClick={() => userUpdate(userData) } >Mettre à jour</Button>
    </div>
           
        </div>
    );
}

export default MonCompte;