import client from "../axiosClient";

// Function to perform a GET request
export const get = async(url) =>{
    try {
        
        const response = await client.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// Function to perform a POST request
export const post = async (url, body) => {
    try {
        const response = await client.post(url, body);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// Function to perform a PUT request
export const put = async (url, body) => {
    try {
        const response = await client.put(url, body);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// Function to perform a DELETE request
export const del = async (url) => {
    try {
        const response = await client.delete(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}