import { useEffect } from 'react';
import client from '../axiosClient';
import axios from 'axios';

/**
 * comptabilise les visites et l'action
 * @param {String} code_event 
 * @param {Number} id_anncs 
 * @param {String} action 
 */
const pageVisitTracker = async (code_event=null, id_anncs=null, action='vue') => {

   // const fetchIpAddress = async () => {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipData = ipResponse.data;
        const ipAddress = ipData.ip || 1;
        
        const userId = localStorage.getItem('userId')

        // Obtenir l'URL actuelle
        const currentUrl = window.location.href;

        // Fonction pour envoyer la requête d'enregistrement de visite avec l'adresse IP et l'URL
        const sendVisit = async () => {
          try {
            const response = await client.post(`/visitor/${action}`, {
                codeEvent : code_event,
                url: currentUrl,
                idAnnonce : id_anncs,
                codeUser: userId,
                ip: ipAddress
              });

            if (response.success) {
              console.log('Visite enregistrée avec succès');
            } else {
              console.error('Échec de l\'enregistrement de la visite');
            }
          } catch (error) {
            console.error('Erreur lors de la requête:', error);
          }
        };

        // Appeler la fonction d'enregistrement de visite lorsque la page est chargée
        sendVisit();
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
      }
  //  };
      // Appeler la fonction pour obtenir l'adresse IP et enregistrer la visite
     // fetchIpAddress();
}

export default pageVisitTracker;
