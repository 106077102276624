import React from 'react';
import CustomPopup from './CustomPopup';

const FormattedPopup = ({ openv, title, btnAction, btnTxt }) => {
    // Contenu formaté avec partie en gras
    const formattedContent = (
        <>
            Tu viens d’atteindre la limite de 5 scans. <strong>Réessaie dans 24h</strong>.
        </>
    );

    return (
        <CustomPopup
            openv={openv}
            title={title}
            content={formattedContent}
            btnAction={btnAction}
            btnTxt={btnTxt}
        />
    );
};

export default FormattedPopup;
