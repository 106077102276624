import React from 'react';
import Internet from '../assets/img/Nointernet.png';
import '../styles/main/error-pages.css';

const OfflinePage = () => {

  return (
    <div className="card bg-transparent text-center border-0" style={{marginTop:'40%'}}>
       <div className="card-header bg-transparent border-0">
            <h4 className="uniform-size" style={{fontSize:'1rem', backgroundColor:'#C4C4C4', textTransform:'uppercase'}}>
              Tu n'as plus internet !
            </h4>
          </div>

      <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
        <img src={Internet} 
        style={{
          width: '350px',   // Default width
          height: 'auto',    // Keeps aspect ratio
          maxWidth: '100%',  // Ensures responsiveness on smaller screens
        }}
        alt="No Internet Illustration" loading="eager" />
        <button onClick={() => window.location.reload()} style={{ margin:'40px', color:'white', backgroundColor:'#C4C4C4', lineHeight:'100%', height:'50px', width:'90%', fontSize:'1.25rem', fontWeight:'bold', padding:'15px 20px', borderRadius:'5px', textAlign:'center', display:'inline-block', textTransform:'uppercase'}}>
          Vérifie ta connexion
          </button>
      </div>
      <div className="advertisement">
        {/* <img src="path/to/advertisement.png" alt="Ad banner" /> */}
      </div>
    </div>
  );
};

export default OfflinePage;