import React, { useState } from "react";
import Popup from 'reactjs-popup';
import ButtonPrimary from "./ButtonPrimary.js";

const CustomPopup = ({ title = "test", content = "bamlbil goyi fbpify vrpiyq fb", btnAction, btnTxt = "okay", openv, btnAction2 = null, btnTxt2 = null }) => {

  const [open, setOpen] = useState(openv);
  const closeModal = () => setOpen(open);
  return (
    <Popup
      open={open}
      toggle={closeModal}
      closeOnDocumentClick
      onClose={closeModal}

      modal
    >
      <span className="close" onClick={closeModal}>
        &times;
      </span>

<div className="wrapper" >

</div>
      <div className="wrap">
        <div className="modal-perso js-modal">
          <div className="modal-perso-image">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#FB4B4B" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.2904 47.693C52.9393 41.4562 52.9393 35.1688 52.2904 28.932C52.059 26.7079 49.4947 25.5814 47.7001 26.9153L41.7157 31.3638C39.8921 32.7194 37.6803 33.4514 35.4081 33.4514H29.8239C28.8171 33.4514 28.001 34.2675 28.001 35.2743V41.3507C28.001 42.3575 28.8171 43.1736 29.8239 43.1736H30.7343L29.5015 47.7745C29.3641 48.2872 29.6147 48.8246 30.0957 49.0489L35.4641 51.5522C35.7571 51.6889 36.0955 51.6889 36.3885 51.5522C36.6816 51.4156 36.8991 51.1563 36.9828 50.8441L38.8242 43.9718C38.8438 43.8988 38.8555 43.8255 38.8598 43.7529C39.877 44.1042 40.841 44.611 41.7157 45.2612L47.7001 49.7097C49.4947 51.0436 52.059 49.9171 52.2904 47.693ZM50.1146 29.1584C50.7479 35.2447 50.7479 41.3803 50.1146 47.4667C50.0587 48.0042 49.4389 48.2765 49.0052 47.9541L43.0207 43.5056C40.8198 41.8696 38.1505 40.9861 35.4081 40.9861L30.1885 40.9861L30.1885 35.6389H35.4081C38.1505 35.6389 40.8198 34.7554 43.0207 33.1194L49.0052 28.6709C49.4389 28.3485 50.0587 28.6208 50.1146 29.1584ZM36.7611 43.2605C36.3143 43.2029 35.8624 43.1736 35.4081 43.1736H32.999L31.852 47.4542L35.2072 49.0188L36.7112 43.4056C36.7247 43.3554 36.7414 43.307 36.7611 43.2605Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="modal-perso-body">
            <h1 className="title" >{title}</h1>
            <p className="content" >{content}</p>

            <div className="buttons" style={{ display: 'flex', justifyContent: " space-around" }} >

              {btnAction2 && btnAction2 !== null ? (
                <div className="actions d-flex justify-content-end">
                  <a
                    type="button"
                    className="text-right"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="btn-open btn-action2"
                      onClick={() => {
                        btnAction2();
                        setOpen(!open)
                      }}
                    >
                      {btnTxt2}
                    </button>
                  </a>
                </div>
              ) : (
                ""
              )}

              {btnAction && btnAction != "" ? (
                <div className="actions d-flex justify-content-end">
                  <a
                    type="button"
                    className="text-right"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="btn-open btn-action "
                      onClick={() => {
                        btnAction();
                        setOpen(!open)
                      }}
                    >
                      {btnTxt}
                    </button>
                  </a>
                </div>


              ) : (
                ""
              )}
            </div>


          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CustomPopup;
