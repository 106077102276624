import { v4 as uuidv4 } from 'uuid';

const userSetter = (function () {

    const setAnonymeUser = function () {
        if (localStorage.getItem('userId') !== null) {
            return localStorage.getItem('userId');
        }
        else {
            localStorage.setItem('userId', uuidv4())
            return localStorage.getItem('userId')
        }
    }

    const checkAnonymeUser = function () {
        if (localStorage.getItem('userId')) {
            return true
        } else {
            return false
        }
    }

    const getAnymonymeId = function () {
        const userId = localStorage.getItem('userId');
        return userId;
    }

    const deleteAnymonymeId = function () {
        localStorage.removeItem('userId');
    }

    return {
        setAnonymeUser,
        checkAnonymeUser,
        getAnymonymeId,
        deleteAnymonymeId
    }
}
)()

export default userSetter;